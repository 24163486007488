<template>
  <toaster @undo-action="undoAction" @close-toaster="closeToaster" has-undo>
    <template v-slot:text>{{ toasterText }}</template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '@/base-components/Toaster';

export default {
  name: 'FixedCommentAddedToaster',
  components: {
    Toaster,
  },
  props: {
    undo: {
      type: Function,
      required: true,
    },
    commentType: {
      type: String, // 'transaction' | 'sequence'
      required: true,
    },
    action: {
      type: String, // 'add' | 'delete'
      required: true,
    },
  },
  computed: {
    toasterText() {
      if (this.action === 'delete') {
        return this.commentType === 'transaction' ? 'ההערה נמחקה' : 'ההערה נמחקה מכל החודשים';
      }
      return this.commentType === 'transaction' ? 'ההערה תוצג רק על ההוצאה הזו' : 'ההערה תוצג בכל החודשים';
    },
  },
  methods: {
    undoAction() {
      this.closeToaster();
      this.undo();
    },
    closeToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
