<template>
  <div class="change-transaction-category-popup">
    <colored-popup :class="categoryToVariant[selectedCategory.cashflowCategory]" :close-action="close">

      <template v-slot:top-content>
        <transaction-menu-header :transaction="transaction" :is-predicted-transaction="predictedTransaction" :category-name="originalCategoryName"/>
      </template>

      <template v-slot:bottom-content>
        <text-input class='margin-top-sm margin-bottom-l'
                    :value="comment"
                    :autofocus="true"
                    label-text="כאן אפשר להוסיף הערה"
                    :on-input="onInput"/>
        <check-box v-if="allowSequenceComment" :toggle="toggleFixed" :initially-checked="isSequenceComment" :disabled="!hasActual">
          <template v-slot:title><span class="ri-label margin-right-xs">להציג את ההערה על ההוצאה הזו בכל החודשים</span></template>
        </check-box>
        <riseup-button :action="onSave"
                       class="margin-top-s"
                       :variant="'primary'"
                       :size="'slim'"
                       :title="primaryButtonText"
                       :disabled="isSaveDisabled"/>
      </template>

      <template v-slot:footer v-if="commentAlreadyExists">
        <div class="cancel" v-on:click="eraseComment">
          <span class="delete-text">מחיקת ההערה</span>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '@/constants/cashflow-view';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import EventBus from '@/event-bus/event-bus';
import Segment from '@/Segment';
import TransactionMenuHeader from '../../../../generic-components/cashflow-types/cashflow-types-base/TransactionMenuHeader';
import FixedCommentAddedToaster from './FixedCommentAddedToaster';

export default {
  name: 'AddTransactionComment',
  mixins: [TransactionDisplayUtils],
  components: {
    TransactionMenuHeader,
    ColoredPopup: BaseUI.ColoredPopup,
    TextInput: BaseUI.TextInput,
    RiseupButton: BaseUI.RiseupButton,
    CheckBox: BaseUI.CheckBox,
  },
  props: {
    predictedTransaction: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.sendSegmentEvent('AddTransactionComment_Shown');
    this.existingComment = this.transaction.sequenceCustomerComment || this.actualTransaction?.customerComment || '';
    this.comment = this.existingComment || this.getPaymentAppDetails(this.transaction) || '';
    this.initialSequenceCommentToggleValue = this.getInitialSequenceCommentToggleValue();
    this.isSequenceComment = this.initialSequenceCommentToggleValue;
  },
  data() {
    return {
      existingComment: '',
      comment: '',
      initialSequenceCommentToggleValue: false,
      isSequenceComment: false,
    };
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'originalCategoryName']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'originalCategory', 'incomeOrExpense']),
    ...mapState('cashflowView', ['allCashflows']),
    isSaveDisabled() {
      return !this.comment || (this.comment === this.existingComment && this.isSequenceComment === this.initialSequenceCommentToggleValue);
    },
    commentAlreadyExists() {
      return !!(this.transaction.sequenceCustomerComment || this.actualTransaction?.customerComment);
    },
    primaryButtonText() {
      if (!this.commentAlreadyExists) {
        return 'הוספת הערה';
      }
      return 'שמירת ההערה';
    },
    isSaving() {
      return this.transaction.isSaving;
    },
    title() {
      if (this.isSaving) {
        return cashflowViewConsts.SAVING_LABEL;
      }
      return `${this.incomeOrExpense} ${this.selectedCategory.label}`;
    },
    allowSequenceComment() {
      return !!(this.transaction.sequenceId && this.transaction.sequenceIdSource !== 'reactive');
    },
    hasActual() {
      return this.predictedTransaction && !!this.transaction.actual;
    },
    actualTransaction() {
      return this.predictedTransaction ? this.transaction.actual : this.transaction;
    },
    transactionId() {
      return this.actualTransaction?.transactionId;
    },
    isTypeChange() {
      return !!this.commentAlreadyExists && this.initialSequenceCommentToggleValue !== this.isSequenceComment;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['setComment', 'setSequenceComment']),
    close() {
      this.sendSegmentEvent('AddTransactionComment_CloseClicked');
      this.$emit('close');
    },
    sendSegmentEvent(eventName, data) {
      Segment.trackUserInteraction(eventName, {
        ...data,
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        transactionId: this.transactionId,
        sequenceId: this.transaction.sequenceId,
      });
    },
    getInitialSequenceCommentToggleValue() {
      if (this.commentAlreadyExists) {
        return !!this.transaction.sequenceCustomerComment;
      }
      return this.allowSequenceComment;
    },
    async onSave() {
      this.sendSegmentEvent('AddTransactionComment_SaveClicked', {
        sequenceComment: this.isSequenceComment,
        isTypeChange: this.isTypeChange,
        editedText: this.comment !== this.existingComment,
      });
      if (this.isTypeChange) {
        this.performReversibleTypeChange();
      } else {
        await this.saveComment(!!this.actualTransaction?.customerComment, !!this.transaction.sequenceCustomerComment);
      }
      this.$emit('close');
    },
    performReversibleTypeChange() {
      const hasExistingTransactionComment = !!this.actualTransaction?.customerComment;
      const hasExistingSequenceComment = !!this.transaction.sequenceCustomerComment;
      const timeoutId = setTimeout(() => {
        this.saveComment(hasExistingTransactionComment, hasExistingSequenceComment);
      }, cashflowViewConsts.TOASTER_TIMEOUT);
      EventBus.$emit('open-toaster', {
        component: FixedCommentAddedToaster,
        props: {
          undo: () => this.undoTypeChange(timeoutId),
          commentType: this.isSequenceComment ? 'sequence' : 'transaction',
          action: 'add',
        },
      });
      this.applyCommentChangeInUiOnly(this.isSequenceComment, this.comment);
    },
    performReversibleSequenceCommentDelete() {
      const timeoutId = setTimeout(() => this.eraseSequenceComment(), cashflowViewConsts.TOASTER_TIMEOUT);
      EventBus.$emit('open-toaster', {
        component: FixedCommentAddedToaster,
        props: {
          undo: () => this.undoDeleteSequenceComment(timeoutId),
          commentType: 'sequence',
          action: 'delete',
        },
      });
      this.applyCommentChangeInUiOnly(true, '');
    },
    undoTypeChange(timeoutId) {
      clearTimeout(timeoutId);
      Segment.trackUserInteraction('AddTransactionComment_UndoClicked', {
        sequenceComment: this.isSequenceComment,
        editedText: this.comment !== this.existingComment,
        action: 'typeChange',
      });
      this.applyCommentChangeInUiOnly(this.initialSequenceCommentToggleValue, this.existingComment);
    },
    undoDeleteSequenceComment(timeoutId) {
      clearTimeout(timeoutId);
      Segment.trackUserInteraction('AddTransactionComment_UndoClicked', {
        sequenceComment: this.isSequenceComment,
        action: 'delete',
      });
      this.applyCommentChangeInUiOnly(true, this.existingComment);
    },
    applyCommentChangeInUiOnly(isSequenceComment, comment) {
      if (isSequenceComment) {
        if (this.actualTransaction) {
          this.actualTransaction.customerComment = '';
        }
        this.transaction.sequenceCustomerComment = comment;
      } else {
        this.actualTransaction.customerComment = comment;
        this.transaction.sequenceCustomerComment = '';
      }
    },
    async saveComment(hasExistingTransactionComment, hasExistingSequenceComment) {
      if (this.isSequenceComment) {
        await this.saveSequenceComment();
        if (hasExistingTransactionComment) {
          this.eraseTransactionComment();
        }
      } else {
        await this.saveTransactionComment();
        if (hasExistingSequenceComment) {
          this.eraseSequenceComment();
        }
      }
    },
    async saveSequenceComment() {
      await this.setSequenceComment({ sequenceId: this.transaction.sequenceId, comment: this.comment });
      this.updatePreviousCashflowsWithSequenceComment(this.comment);
    },
    updatePreviousCashflowsWithSequenceComment(comment) {
      this.allCashflows.forEach(cashflow => {
        cashflow.envelopes
          .filter(envelope => envelope.type === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED
            && envelope.details?.sequenceId === this.transaction.sequenceId)
          .forEach(envelope => {
            // eslint-disable-next-line no-param-reassign
            envelope.sequenceCustomerComment = comment;
          });
      });
    },
    async saveTransactionComment() {
      await this.setComment({ transactionId: this.transactionId, comment: this.comment });
      if (this.predictedTransaction) {
        this.transaction.actual.customerComment = this.comment;
      } else {
        this.transaction.customerComment = this.comment;
      }
    },
    onInput(comment) {
      this.comment = comment;
    },
    async eraseComment() {
      this.sendSegmentEvent('AddTransactionComment_DeleteClicked', { sequenceComment: this.initialSequenceCommentToggleValue });
      if (this.initialSequenceCommentToggleValue) {
        this.performReversibleSequenceCommentDelete();
      } else {
        await this.eraseTransactionComment();
      }
      this.$emit('close');
    },
    async eraseSequenceComment() {
      await this.setSequenceComment({ sequenceId: this.transaction.sequenceId, comment: '' });
      this.updatePreviousCashflowsWithSequenceComment('');
    },
    async eraseTransactionComment() {
      await this.setComment({ transactionId: this.transactionId, comment: '' });
    },
    toggleFixed() {
      this.isSequenceComment = !this.isSequenceComment;
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.change-transaction-category-popup {
  text-align: right;

  .colored-top {
    color: $riseup_white;

    .expense-title {
      margin-bottom: 16px;
      line-height: 11px;

      .name, .dot{
        font-weight: bold;
      }
    }
  }

  .prompt {
    line-height: 13px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .text-input {
    margin-bottom: 40px;
  }

  .riseup-button {
    width: 100%;
  }

  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .delete-text {
      color: $riseup_black;
      font-size: 18px;
    }
  }
}

</style>
